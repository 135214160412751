export const GENDERS = {
    MALE: 'Мужчина',
    FEMALE: 'Женщина',
    ALL: 'Любой',
}

export const GENDERS_TO_NUMBERS = {
    MALE: 1,
    FEMALE: 0,
    ALL: undefined,
}

export const EMOTIONS = {
    neutral: 'Нейтральный',
    sad: 'Расстроенный',
    angry: 'Злой',
    disgust: 'Отвращение',
    fear: 'Испуганный',
    happy: 'Счастливый',
    surprise: 'Удивленный',
}

export const COLORS = {
    MALE: '#4aa8dc',
    FEMALE: '#fc8383',
}

export const PERSONS_LOG_DISPLAY_MODES = {
    TABLE: 1,
    GRID: 2,
    GRAPHS: 3,
    DIAGNOSTICS: 4,
}

export const LOGS_ITEMS_PER_PAGE = [12, 28, 48]

export const REPORT_OUTPUT_FORMATS = {
    JSON: 'json',
    CSV: 'csv',
}

export const FACE_FILTERS = {
    HasMask: 1,
    HasSunGlasses: 2,
    HasMedGlasses: 3,
    HasBeard: 4,
}

export const ATTRIBUTES = {
    [FACE_FILTERS.HasBeard]: 'Борода',
    [FACE_FILTERS.HasMask]: 'Медицинская маска',
    [FACE_FILTERS.HasMedGlasses]: 'Медицинские очки',
    [FACE_FILTERS.HasSunGlasses]: 'Солнцезащитные очки',
}
